<template>
	<div class="sign-in">

		<div class="login">
			<div class="side-login">
				<!-- INFORMAÇÕES NO TOP -->
				<div class="info-top">
					<img src="/images/logo-aliga.png" style="width: 140px;">
				</div>

				<div class="info-login">
					<a-col :span="24" :md="24" :lg="24" class="col-form"
						style="max-width: 500px;  height: 100%; display: flex; align-items: center; justify-content: space-around; flex-direction: column; ">

						<div v-if="hasProductor">
							<div v-if="!hasCode" style="font-size: 2.7rem;">
								<div v-if="!isRegister" class="title">
									<h1>Bem-vindo(a) à Aliga! 👋</h1>
									<p><strong>Faça o login</strong> agora para iniciar esta emocionante jornada rumo ao
										êxito..
									</p>
								</div>
								<div v-if="isRegister" class="title">
									<h1>Seja um produtor! 👋</h1>
									<p>Juntos alcançaremos o sucesso! <strong>Crie sua conta</strong> vamos começar essa
										jornada incrível.
									</p>
								</div>

								<!-- 					<span v-if="!isRegister" style="font-size: 1rem !important;">Junte-se a</span> -->
								<!-- <span v-if="isRegister" style="font-size: 1rem !important;">Seja um produtor
								da</span><br><img src="/images/logo-aliga.png" style="width: 150px;"> -->

							</div>
							<p v-else style="font-size: 2.7rem;  font-weight: bold; line-height: 1.1;"><span
									style="font-size: 1rem !important;">Você está se juntando a </span><br><img
									src="/images/logo-aliga.png" style="width: 150px;">
							</p>
						</div>


						<div v-if="!hasProductor" style="text-align: center;">
							<!-- LOGIN -->
							<div v-if="!isRegister" class="title">
								<h1>Bem-vindo(a) à Aliga! 👋</h1>
								<p><strong>Faça o login</strong> agora para iniciar esta emocionante jornada rumo ao êxito..
								</p>
							</div>

							<!-- CRIAR CONTA -->
							<div>
								<div v-if="isRegister" class="title">
									<h1>A aventura começa aqui 🚀</h1>
									<p>Juntos alcançaremos o sucesso! <strong>Crie sua conta</strong> vamos começar essa
										jornada
										incrível.</p>
								</div>
							</div>

							<p v-if="!hasCode" style="font-size: 2.7rem;  font-weight: bold; line-height: 1.1;"></p>
							<p v-else style="font-size: 2.7rem;  font-weight: bold; line-height: 1.1;"><span
									style="font-size: 1rem !important;">Você está se juntando a </span><br><img
									src="/images/logo-aliga.png" style="width: 150px;">
							</p>
						</div>


						<div v-if="hasCode" style="text-align: center;">
							<div class="ui-loader loader-blk">
								<svg viewBox="22 22 44 44" class="multiColor-loader">
									<circle cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"
										class="loader-circle loader-circle-animation"></circle>
								</svg>
							</div>
						</div>

						<!-- BOTÃO ENTRAR/CRIAR -->
						<a-form v-if="!hasCode" id="components-form-demo-normal-signup" :form="form" class="signup-form"
							:hideRequiredMark="true">
							<!-- BOTÃO -->
							<a-form-item>
								<a-button type="secondary"
									style="background-color: #0d0f15; border: 1px solid #6a38c4; border-radius: 15px; width: 100%;"
									block class="signup-form-button" :loading="isLoadingGoogle" @click="signUpGoogle()">
									<img src="images/logos/Google__G__Logo.svg.png" alt=""
										style="width: 20px; margin-right: 5px;">Continuar com o Google
								</a-button>
							</a-form-item>

							<!-- TERMOS -->
							<p class="text-muted text-center" style="font-size: 11px; margin-top: -10px !important;">
								Ao selecionar "Continuar com o Google", você concorda com os <a>Termos & Condições</a> e
								<a>Políticas de privacidade</a>
							</p>
						</a-form>

						<!-- NOVO/ENTRAR -->
						<div v-if="!hasCode" style="margin-top: 2rem;">
							<p v-if="isRegister" class="font-semibold text-muted text-center">Já tem uma conta? <a
									@click="isRegister = false">Entre</a></p>
							<p v-else class="font-semibold text-muted text-center">Ainda não faz parte da Aliga? <a
									@click="isRegister = true">
									Cadastre-se</a></p>
						</div>
					</a-col>
				</div>


				<!-- INFORMAÇÕES NO BOTTOM -->
				<div class="info-bottom">
					<!-- banner -->
					<div class="banner">
						<img id="desktop" src="/images/banners/banner-forum.png" alt="">
						<img id="mobile" src="/images/banners/banner-forum-mobile.png" alt="">

					</div>
				</div>
			</div>
			<div class="side-info">
				<img src="/images/logo-white.png" alt="" style="width:250px; ">
			</div>
		</div>

		<!-- FOOTER -->
		<div class="footer">
			<p class="copyright-kemo" style="font-size: small;">
				Copyright © 2023 <a href="https://kemosoft.com.br">Kemo</a>
			</p>
		</div>

	</div>
</template>

<script>

export default ({
	data() {
		return {
			// Sign up form object.
			isRegister: false,
			hasCode: false,
			hasProductor: false,
			isLoadingGoogle: false,
			isLoadingFacebook: false,
			form: this.$form.createForm(this, { name: 'signup_cover' }),
			isDesktop: window.innerWidth > 768, // Defina aqui o tamanho limite para considerar como "desktop"
		}
	},
	methods: {
		signUpGoogle() {
			// if(this.hasProductor){
			// 	localStorage.setItem("typeMember", "productor");
			// }
			this.isLoadingGoogle = true;
			window.location.href = "https://ms-league-az.kemosoft.com.br/auth/google";
		},

		signUpFacebook() {
			this.isLoadingGoogle = true;
			window.location.href = "https://ms-league-az.kemosoft.com.br/auth/google";
		},
		// Handles input validation after submission.
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
					console.log('Received values of form: ', values);
				}
			});
		},
		getParameterByName(name, url) {
			if (!url) url = window.location.href;
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		},
		// Método para capturar e imprimir os parâmetros da URL no console
		captureURLParameters() {

			const code = this.getParameterByName('code');
			const tym = this.getParameterByName('tym');

			if (code) {
				this.hasCode = true;

				this.$api.authService.redirectGoogle(code)
					.then((res) => {
						localStorage.setItem("accessToken", res.data.accessToken);
						localStorage.setItem("expiresIn", res.data.expiresIn);
						localStorage.setItem("refreshToken", res.data.refreshToken);
						localStorage.setItem("userType", res.data.userType);

						if (res.data.userType === "unknown") {
							const registrationPage = tym === '1' ? "/register-producer" : "/register-member";
							this.$router.push(registrationPage);
						} else {
							this.$router.push("/dashboards/member");
						}
					})
					.catch(error => {
						console.error(error);
						this.$router.push("/login");
						// Handle error if needed
					});
			}
		},
	},
	created() {
		const tym = this.getParameterByName('tym');
		const register = this.getParameterByName('new');

		if (tym == '1') {
			this.hasProductor = true;
		}

		if (tym != null) {
			localStorage.setItem("tym", tym);
		}

		if (register != null) {
			this.isRegister = true;
		}
	},
	mounted() {

		// Chame a função para capturar os parâmetros da URL assim que o componente for montado
		this.captureURLParameters();

	},
})

</script>

<style lang="scss">
.gradient {
	background-image: var(--degrade-rosa, linear-gradient(50deg, #6648F6 0%, #FE2EE4 100%));
	color: black;
	background-clip: text;
	-webkit-background-clip: text;

	/* Adicionado: */
	-webkit-text-fill-color: transparent;
}

/* //codelessly loader style */
.ui-loader {
	display: inline-block;
	width: 50px;
	height: 50px;
}

.loader-blk {
	color: #3f51b5;
	animation: rotate-outer08 1.4s linear infinite;
}

.multiColor-loader {
	display: block;
	animation: color-anim08 1.4s infinite;
}

.loader-circle {
	stroke: currentColor;
}

.MuiCircularProgress-circleStatic {
	transition: stroke-dashoffset 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.loader-circle-animation {
	animation: rotate-inner08 1.4s ease-in-out infinite;
	stroke-dasharray: 80px, 200px;
	stroke-dashoffset: 0;
}

@keyframes rotate-outer08 {
	0% {
		transform-origin: 50% 50%;
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate-inner08 {
	0% {
		stroke-dasharray: 1px, 200px;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 100px, 200px;
		stroke-dashoffset: -15px;
	}

	100% {
		stroke-dasharray: 100px, 200px;
		stroke-dashoffset: -125px;
	}
}

@keyframes color-anim08 {
	0% {
		color: #4286f4;
	}

	25% {
		color: #4435ea;
	}

	50% {
		color: #FE2EE4;

	}

	75% {
		color: #6648F6;
	}
}

.sign-in {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 0 !important;

	.login {
		padding: 2rem 2rem 0 2rem;
		height: 100%;

		display: flex;
		flex-direction: row;

		.side-login {
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.info-top {
				height: 15%;
				width: 90%;

				display: flex;
				align-items: center;
				justify-content: left;
			}

			.info-login {
				.title {
					text-align: left;

					h1 {
						font-size: 20px;
					}

					p {
						font-size: 16px;
					}
				}
			}

			.info-bottom {
				width: 90%;

				.banner {
					background-image: var(--degrade-rosa, linear-gradient(50deg, #FE2EE4 0%, #6648F6 100%));

					padding: 5px;
					border-radius: 10px;

					img {
						width: 100%;
					}

					animation: slideUp 1s ease-in-out 2s forwards;
				}

				@keyframes slideUp {
					from {
						transform: translateY(100%);
						opacity: 0;
					}

					to {
						transform: translateY(0);
						opacity: 1;
					}
				}

			}
		}

		.side-info {
			background-image: var(--degrade-rosa, linear-gradient(50deg, #6648F6 0%, #FE2EE4 100%));
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			border-radius: 10px;
		}

		@media (max-width: 800px) {
			.side-info {
				display: none;
			}
		}
	}
}

@media (max-width: 800px) {
	.login {

		.side-info {
			display: none;
		}

		.side-login {
			width: 100% !important;

			.info-top {
				width: 100% !important;
			}

			.info-bottom {
				width: 100% !important;
			}
		}
	}
}

.footer {
	text-align: center;

	.copyright-kemo {
		margin-top: 1rem;
		padding: 10px 0 2px 0 !important;
	}
}

.banner {
	#desktop {
		display: block;
	}

	#mobile {
		display: none;
	}

	@media (max-width: 700px) {
		#desktop {
			display: none;
		}

		#mobile {
			display: block;
		}
	}
}
</style>